var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('div',{staticClass:"request-management"},[_c('CRow',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"12","cols":"12","lg":"4"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{attrs:{"sm":"12","md":"12","lg":"8"}})],1),_c('Grid',{ref:"grid",staticClass:"request",attrs:{":resizable":true,"sortable":true,"filterable":true,"pageable":_vm.gridPageable,"filter":_vm.filter,"take":_vm.take,"skip":_vm.skip,"sort":_vm.sort,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns},on:{"datastatechange":_vm.dataStateChange,"sortchange":_vm.sortChange},scopedSlots:_vm._u([{key:"additional_services_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter(
                                'is_additional_services',
                                $event.value.value
                            )}}})]},proxy:true},{key:"is_additional_services",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_c('span',{staticClass:"m-badge m-badge--wide m-badge--wide",class:{
                                'm-badge--success':
                                    dataItem.is_additional_services,
                                'm-badge--danger':
                                    !dataItem.is_additional_services,
                            }},[_vm._v(" "+_vm._s(dataItem.is_additional_services ? 'ДА' : 'НЕТ')+" ")])])]}},{key:"price",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("fractionSizeSpace")(dataItem.price))+" ")])]}},{key:"full_cost",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm._f("fractionSizeSpace")(dataItem.full_cost))+" ")])]}},{key:"status_filter",fn:function(){return [_c('boolean-filter',{on:{"change":function($event){return _vm.selectFilter('status', $event.value.value)}}})]},proxy:true},{key:"status",fn:function(ref){
                            var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(dataItem.status_name)+" ")])]}}])}),_c('v-row',{staticClass:"mt-3 mb-2"},[_c('v-col',{staticClass:"pl-0 d-flex justify-end",attrs:{"cols":"12"}},[_c('CButton',{staticClass:"export-excel-btn",attrs:{"color":"success"},on:{"click":_vm.downloadReport}},[_c('i',{staticClass:"mr-2 fa fa-save"}),_c('span',[_vm._v("Экспорт в Excel")])])],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }