<template>
    <localization :language="'ru-RU'">
        <intl :locale="'ru'">
            <div class="request-management">
                <CRow class="table-actions-row">
                    <v-col sm="12" cols="12" lg="4" class="pl-0">
                        <breadcrumbs :items="crumbs" />
                    </v-col>
                    <v-col sm="12" md="12" lg="8"></v-col>
                </CRow>
                <Grid
                    ref="grid"
                    class="request"
                    ::resizable="true"
                    :sortable="true"
                    :filterable="true"
                    :pageable="gridPageable"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :take="take"
                    :skip="skip"
                    :sort="sort"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    @sortchange="sortChange"
                >
                    <template v-slot:additional_services_filter>
                        <boolean-filter
                            @change="
                                selectFilter(
                                    'is_additional_services',
                                    $event.value.value
                                )
                            "
                        />
                    </template>
                    <template
                        v-slot:is_additional_services="{ props: { dataItem } }"
                    >
                        <td>
                            <span
                                class="m-badge m-badge--wide m-badge--wide"
                                :class="{
                                    'm-badge--success':
                                        dataItem.is_additional_services,
                                    'm-badge--danger':
                                        !dataItem.is_additional_services,
                                }"
                            >
                                {{
                                    dataItem.is_additional_services
                                        ? 'ДА'
                                        : 'НЕТ'
                                }}
                            </span>
                        </td>
                    </template>
                    <template v-slot:price="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.price | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:full_cost="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.full_cost | fractionSizeSpace }}
                        </td>
                    </template>
                    <template v-slot:status_filter>
                        <boolean-filter
                            @change="selectFilter('status', $event.value.value)"
                        />
                    </template>
                    <template v-slot:status="{ props: { dataItem } }">
                        <td>
                            {{ dataItem.status_name }}
                        </td>
                    </template>
                </Grid>
                <v-row class="mt-3 mb-2">
                    <v-col cols="12" class="pl-0 d-flex justify-end">
                        <CButton
                            @click="downloadReport"
                            class="export-excel-btn"
                            color="success"
                        >
                            <i class="mr-2 fa fa-save"></i
                            ><span>Экспорт в Excel</span>
                        </CButton>
                    </v-col>
                </v-row>
            </div>
        </intl>
    </localization>
</template>

<script>
    import api from '@/api';
    import { mapActions, mapGetters } from 'vuex';
    import { Grid } from '@progress/kendo-vue-grid';
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl';
    import '@/views/grid_elements/translations';
    import TableMixin from '@/views/grid_elements/mixins/TableMixin';
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin';
    import Breadcrumbs from '@/views/components/breadcrumbs';
    import { DropDownList } from '@progress/kendo-vue-dropdowns';
    import BooleanFilter from '@/views/components/BooleanFilter';
    import { DatePicker } from '@progress/kendo-vue-dateinputs';
    import { toDataSourceRequestString } from '@progress/kendo-data-query';

    export default {
        name: 'request-statistics',
        components: {
            localization: LocalizationProvider,
            intl: IntlProvider,
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            datepicker: DatePicker,
            dropdownlist: DropDownList,
            BooleanFilter,
        },
        mixins: [TableMixin, ResizableTableMixin],
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 7,
                columns: [
                    {
                        field: 'number',
                        title: 'Номер заказа',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'created_at',
                        title: 'Дата создания заявки',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'dealer_name',
                        title: 'Дилер',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'unique_id',
                        title: 'КССС',
                        type: 'text',
                        filter: 'text',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'start_at',
                        title: 'Дата обучения',
                        type: 'date',
                        filter: 'date',
                        format: '{0:d}',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'title',
                        title: 'Название курса',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'user_count',
                        title: 'Количество участников',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'format',
                        title: 'Онлайн/Офлайн',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'city',
                        title: 'Город проведения',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                    {
                        field: 'price',
                        cell: 'price',
                        title: 'Сумма заказа',
                        type: 'numeric',
                        filter: 'numeric',
                        minWidth: 200,
                        width: '200px',
                    },
                    {
                        field: 'status',
                        title: 'Статус',
                        cell: 'status',
                        filterCell: 'status_filter',
                        minWidth: 160,
                        width: '160px',
                    },
                    {
                        field: 'curator_from_dealer_name',
                        title: 'Контакты ФИО',
                        type: 'text',
                        minWidth: 250,
                        width: '250px',
                    },
                ],
            };
        },
        mounted() {
            if (this.isCurrentUserAdministrator) {
                this.fetchAgencyListShort();
            }

            this.fetchDealerListShort();
            this.getData();
        },
        computed: {
            ...mapGetters('dealer', ['dealersListShort']),
            dealerFilter() {
                return [
                    {
                        id: null,
                        name: 'Все',
                    },
                    ...this.dealersListShort,
                ];
            },
        },
        methods: {
            fetchData: api.agent_organizer.getEducationOrdersManagement,
            ...mapActions('dealer', ['fetchDealerListShort']),
            selectDealer({ value }) {
                if (value.id) {
                    this.setFilter('dealer_id', 'eq', value.id);
                } else {
                    this.removeFilter('dealer_id');
                }

                this.updateTable(true);
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ];
            },
            async downloadReport() {
                try {
                    const response =
                        await api.agent_organizer.getEducationOrdersStatisticsReport(
                            toDataSourceRequestString({
                                filter: this.filter,
                                sort: this.sort,
                            })
                        );

                    const fileURL = window.URL.createObjectURL(
                        new Blob([response.data])
                    );
                    const fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', `Заказы.xlsx`);
                    document.body.appendChild(fileLink);

                    fileLink.click();
                } catch (e) {
                    console.warn(e);
                }
            },
        },
    };
</script>
